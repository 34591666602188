<div class="d-flex flex-row align-items-center">
	<div *ngIf="isInitialized && deviceLocations" class="mx-2">{{ pagingStatus }}</div>
	<div class="ms-auto me-2">
		<button type="button" class="btn btn-link ms-auto" (click)="onRefresh()"><fa-icon icon="sync" class="me-1"></fa-icon>Refresh</button>
		<button *ngIf="hasSubscription" type="button" class="btn btn-link ms-2" (click)="downloadCsv()">
			<fa-icon icon="sync" class="me-1"></fa-icon>Download CSV
		</button>
	</div>
	<div>
		<app-root-monitor-cloud-data-table-search-form
			*ngIf="isInitialized && searchConfig && initialSearchFilter"
			[searchConfiguration]="searchConfig"
			[initialSearchFilter]="initialSearchFilter"
			(search)="onSearch($event)">
		</app-root-monitor-cloud-data-table-search-form>
	</div>
</div>
<div *ngIf="isInitialized === true && deviceLocations?.length > 0" class="shared-accordion mt-1">
	<ngb-accordion #acc="ngbAccordion" [animation]="false">
		<ngb-panel *ngFor="let location of visibleLocations; let rowIndex = index" [id]="location.locationId">
			<ng-template ngbPanelHeader let-opened="opened">
				<button ngbPanelToggle class="toggle-button" [ngClass]="{ opened: opened === true }">
					<fa-icon [icon]="opened === true ? 'chevron-down' : 'chevron-right'" size="md" class="me-2"></fa-icon>
					<div class="d-flex w-100">
						<div class="px-1 text-start d-flex align-items-center" style="width: 35%; font-size: 0.95rem">
							<div class="me-1"><fa-icon icon="map-marker-alt" size="lg"></fa-icon></div>
							<div class="toggle-title" [ngClass]="{ opened: opened === true }">{{ location.name }}</div>
						</div>
						<div class="px-1 text-start" style="width: 30%; font-size: 0.95rem">
							<div>
								<fa-icon icon="server" size="md"></fa-icon>
								{{ location.devices }} Agent{{ location.devices !== 1 ? 's' : '' }}
							</div>
							<div>
								<fa-icon icon="router" size="md"></fa-icon>
								{{ location.managedDevices }} Network Device{{ location.managedDevices !== 1 ? 's' : '' }}
							</div>
						</div>
						<div class="px-1 text-start d-flex align-items-center" style="width: 20%; font-size: 0.95rem">
							<div *ngIf="hasSubscription">
								<fa-icon icon="bell" size="lg"></fa-icon>{{ location.openAlerts }} open / {{ location.criticalAlerts }} critical
							</div>
							<div *ngIf="!hasSubscription">--</div>
						</div>
						<div *ngIf="isAdmin" class="ms-auto" (click)="preventRowToggle($event)">
							<div ngbDropdown class="d-flex float-right">
								<button type="button" class="btn btn-ellipsys  after-none" id="accordionActionsDropdown_{{ rowIndex }}" ngbDropdownToggle>
									<fa-icon icon="ellipsis-v" size="2x"></fa-icon>
								</button>
								<div ngbDropdownMenu class="accordion__menu dropdown-menu dropdown-menu-right">
									<button ngbDropdownItem (click)="editLocation(location)" class="d-flex px-1">
										<fa-icon icon="edit" class="text-primary mx-2"></fa-icon> Edit Location
									</button>
								</div>
							</div>
						</div>
					</div>
				</button>
			</ng-template>
			<ng-template ngbPanelContent>
				<div class="accordion-body-content">
					<ng-container [ngTemplateOutlet]="tabs" [ngTemplateOutletContext]="{ location: location }"></ng-container>
				</div>
			</ng-template>
		</ngb-panel>
	</ngb-accordion>
    <div class="d-flex justify-content-between p-2">
		<ngb-pagination
			[ellipses]="true"
			[maxSize]="3"
			[collectionSize]="deviceLocations?.length || 0"
			[(page)]="page"
			[pageSize]="pageSize"
			[boundaryLinks]="true"
            (pageChange)="onPageChange($event)"></ngb-pagination>
		<div class="page-size">
			show
			<select class="form-select form-select-sm" style="width: auto" name="pageSize" [(ngModel)]="pageSize" (change)="onPageSizeChange()">
				<option [ngValue]="10">10</option>
				<option [ngValue]="20">20</option>
				<option [ngValue]="50">50</option>
			</select>
			per page
		</div>
	</div>
</div>
<shared-context-message *ngIf="isInitialized && (deviceLocations?.length || 0) == 0" type="dark">No devices available.</shared-context-message>

<ng-template #tabs let-location="location">
	<ul
		ngbNav
		#panel="ngbNav"
		[activeId]="location?.devices > 0 || location?.managedDevices == 0 ? 'units' : 'managed-devices'"
		class="nav-tabs page-content-tabs">
		<li ngbNavItem="units">
			<a ngbNavLink><fa-icon icon="server"></fa-icon> Agents{{ location?.devices != null ? ' (' + location.devices + ')' : '' }}</a>
			<ng-template ngbNavContent>
				<app-root-monitor-cloud-unit-table
					[unitData]="location.deviceList"
					[showDownloadCsv]="false"
					[showSearch]="false"
                    [showRefresh]="false"
                    (update)="onUpdate()"></app-root-monitor-cloud-unit-table>
			</ng-template>
		</li>
		<li ngbNavItem="managed-devices">
			<a ngbNavLink>
				<fa-icon icon="router"></fa-icon> Network Devices{{ location?.managedDevices != null ? ' (' + location.managedDevices + ')' : '' }}
			</a>
			<ng-template ngbNavContent>
				<app-root-monitor-cloud-managed-device-table
					[managedDevicesData]="location.managedDeviceList"
					[showDownloadCsv]="false"
					[showSearch]="false"
                    [showRefresh]="false"
					(update)="onUpdate()"></app-root-monitor-cloud-managed-device-table>
			</ng-template>
		</li>
	</ul>
	<div [ngbNavOutlet]="panel"></div>
</ng-template>
