<ul ngbNav #panel="ngbNav" [activeId]="activeTabKey" class="nav-tabs page-side-panel-tabs">
	<li ngbNavItem="details">
		<a ngbNavLink>Details</a>
		<ng-template ngbNavContent>
			<ng-container [ngTemplateOutlet]="details"></ng-container>
		</ng-template>
	</li>
	<li ngbNavItem="alerts">
		<a ngbNavLink>Alerts</a>
		<ng-template ngbNavContent>
			<ng-container [ngTemplateOutlet]="alerts"></ng-container>
		</ng-template>
	</li>
	<li ngbNavItem="neighbors">
		<a ngbNavLink>Neighbors</a>
		<ng-template ngbNavContent>
			<ng-container [ngTemplateOutlet]="neighbors"></ng-container>
		</ng-template>
	</li>
</ul>
<div [ngbNavOutlet]="panel"></div>

<ng-template #details>
	<app-root-monitor-cloud-managed-device-details-side-panel [managedDevice]="managedDevice"></app-root-monitor-cloud-managed-device-details-side-panel>
</ng-template>

<ng-template #alerts>
	<ng-container *ngIf="(managedDevice?.alerts?.length || 0) == 0">
		<div class="pt-3">No alerts available.</div>
	</ng-container>
	<ng-container *ngIf="managedDevice?.alerts?.length > 0">
		<app-root-monitor-cloud-alert-side-panel
			[accountId]="tab.accountId"
			[alertItemsFromServer]="managedDevice?.alerts"
			(updateAlerts)="updateAlerts($event)">
		</app-root-monitor-cloud-alert-side-panel>
	</ng-container>
</ng-template>

<ng-template #neighbors>
	<ng-container *ngIf="(managedDevice?.lldpNeighbors?.length || 0) == 0">
		<div class="pt-3">No neighbors available.</div>
	</ng-container>
	<ng-container *ngIf="managedDevice?.lldpNeighbors?.length > 0">
		<div class="my-3">
			<fa-icon icon="info-circle" size="lg" class="me-3"></fa-icon>
			<span class="text-sm font-italic">Devices found using Link Local Discovery Protocol (LLDP)</span>
		</div>
		<table class="table table-dark bg-none">
			<thead>
				<tr>
					<th>Port</th>
					<th>Mac Address</th>
					<th>Sys Name</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let neighbor of managedDevice?.lldpNeighbors">
					<td>
						{{ neighbor.arrivalPortIndex || '--' }}
					</td>
					<td>
						{{ formatMacAddress(neighbor.macAddress) || '--' }}
					</td>
					<td title="{{ neighbor.sysDesc }}">
						{{ neighbor.sysName || '--' }}
					</td>
				</tr>
			</tbody>
		</table>
	</ng-container>
</ng-template>
