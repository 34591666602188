import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertTotals, AuthService, DataSearchConfiguration, SearchFilter } from '@razberi-ui/api/cloud-api';
import { Account, ManagedDevice, Location, Tag, Unit, UserRole, AlertStatus, EnumService } from '@razberi-ui/core/data-types';
import { AlertMessageService, UtilitiesService } from '@razberi-ui/shared';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { SubscriptionService } from '../../../services/subscription.service';
import { AccountService } from '../../../services/account.service';
import { UnitService } from '../../../services/unit.service';
import { AlertService } from '../../../services/alert.service';
import { ReportService } from '../../../services/report.service';
import { ManagedDeviceService } from '../../../services/managed-device.service';
import { LocationModalComponent } from '../../locations/location-modal/location-modal.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-location-devices',
	templateUrl: './location-devices.component.html',
	styleUrls: ['./location-devices.component.scss'],
})
export class LocationDevicesComponent {
	@Input() refresh$: Observable<void>;

	initialSearchFilter: SearchFilter = {};
	searchFilter: SearchFilter;
	searchConfig: DataSearchConfiguration;
	isAdmin: boolean;
	account: Account;
	devices: Unit[] = [];
	managedDevices: ManagedDevice[] = [];
	tags: Tag[] = [];
	alertTotals: AlertTotals[] = [];
    accountLocations: Location[] = [];
	deviceLocations: Location[] = [];
    visibleLocations: Location[] = [];
	expandedLocationIds: string[] = [];
	hasSubscription: boolean = false;
	subscriptions: Subscription = new Subscription();
	isInitialized: boolean = false;

    page: number = 1;
    pageSize: number = 10;
	get pagingStatus() {
		return this.utils.helpers.getPagingStatus(this.page, this.pageSize, this.deviceLocations?.length ?? 0);
	}

	constructor(
		private readonly modalService: NgbModal,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly alertMessageService: AlertMessageService,
		private readonly authService: AuthService,
		private readonly subscriptionService: SubscriptionService,
		private readonly accountService: AccountService,
		private readonly unitService: UnitService,
		private readonly alertService: AlertService,
		private readonly reportService: ReportService,
		private readonly managedDeviceService: ManagedDeviceService,
		private readonly utils: UtilitiesService,
		private readonly enumService: EnumService
	) {}

	ngOnInit() {
		this.isAdmin = this.authService.helpers.userHasRole(UserRole.Administrator) == true;
		this.searchFilter = { ...this.initialSearchFilter };
		this.searchConfig = { showSearchTerm: true, showLocations: true, showTags: true, showUnitStatuses: true };
		this.hasSubscription = this.subscriptionService.helpers.isGlobalOrIsValid();
		if (this.refresh$)
			this.subscriptions.add(
				this.refresh$.subscribe((_) => {
					this.getDevices();
				})
			);
        this.initAccountData();
	}

    initAccountData() {
        this.accountService.api.getAccountsData([this.authService.data.account.accountId]).subscribe(accounts => {
            this.account = accounts[0];
            this.account.locations.push({
                locationId: 0,
                accountId: this.authService.data.account.accountId,
                name: 'No Location',
                description: 'No location has been set.',
            });
            this.accountLocations = this.account.locations?.sort((l1: Location, l2: Location) => {
                if (l1.locationId === 0) return 1;
                if (l2.locationId === 0) return -1;
                if (l1.name.toLowerCase() < l2.name.toLowerCase()) return -1;
                if (l1.name.toLowerCase() > l2.name.toLowerCase()) return 1;
                return 0;
            });
            this.tags = this.account.tags?.sort((t1: Tag, t2: Tag) => {
                if (t1.name.toLowerCase() < t2.name.toLowerCase()) return -1;
                if (t1.name.toLowerCase() > t2.name.toLowerCase()) return 1;
                return 0;
            }) ?? [];

            this.getDevices();
        })   
    }

	getDevices() {
        forkJoin({
            devices: this.unitService.api.getUnits({
                accounts: [{ accountId: this.authService.data.account.accountId }],
                sortAsc: false,
                ...this.searchFilter,
            }),
            managedDevices: this.managedDeviceService.api.getManagedDevices({
                accounts: [{ accountId: this.authService.data.account.accountId }],
                managedDeviceTypes: this.enumService.helpers.getManagedDeviceTypes(false)?.map((t) => t.value),
                sortAsc: false,
                ...this.searchFilter,
            }),
            alertTotals: this.alertService.api.getDeviceAlertTotals(this.authService.data.account.accountId),
        }).subscribe((results) => {
            this.devices = results.devices.results;
            this.managedDevices = results.managedDevices.results;
            this.alertTotals = results.alertTotals;

            if (this.accountLocations?.length > 0) {
                let filteredTotals: AlertTotals[] = [];
                if (this.alertTotals) filteredTotals = this.alertTotals.filter((at: AlertTotals) => this.devices.some((d: Unit) => d.unitId === at.unitId));
                this.accountLocations.forEach((l) => {
                    (<any>l).deviceList = this.devices.filter((device: Unit) => (device.locationId ?? 0) === l.locationId);
                    l.devices = (<any>l).deviceList.length;
                    (<any>l).managedDeviceList = this.managedDevices.filter(
                        (managedDevice: ManagedDevice) => (managedDevice.cloudLocationId ?? 0) === l.locationId
                    );
                    l.managedDevices = (<any>l).managedDeviceList.length;
                    l.openAlerts = 0;
                    l.criticalAlerts = 0;
                    if (this.alertTotals) {
                        l.openAlerts = (<any>l).deviceList.reduce((sum, d) => sum + this.getOpenAlerts(filteredTotals, d.unitId), 0);
                        l.criticalAlerts = (<any>l).deviceList.reduce((sum, d) => sum + this.getCriticalAlerts(filteredTotals, d.unitId), 0);
                    }
                });

                this.deviceLocations = [...this.accountLocations?.filter(l => l.devices > 0)];
                this.paginate();
            }

            this.changeDetectorRef.detectChanges();
            this.isInitialized = true;
        })
	}

    paginate() {
        this.visibleLocations = [...this.deviceLocations.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)];
    }

    onPageChange() {
        this.paginate();
    }

    onPageSizeChange() {
        this.paginate();
    }

	editLocation(location: Location) {
		const modal: NgbModalRef = this.modalService.open(LocationModalComponent, { backdrop: 'static', centered: true });
		modal.componentInstance.accountId = location.accountId;
		modal.componentInstance.locationId = location.locationId;
		modal.result.then(
			(_) => {
				this.getDevices();
			},
			(_) => {}
		);
	}

	onRefresh() {
		this.initAccountData();
	}

    onUpdate() {
		this.getDevices();
	}

	onSearch(searchFilter: SearchFilter) {
		this.searchFilter = searchFilter;
		this.getDevices();
	}

	onPanelChange(event) {
		if (event) {
			if (this.expandedLocationIds.find((l) => l === event.panelId))
				this.expandedLocationIds = this.expandedLocationIds.filter((l) => l !== event.panelId);
			else {
				this.expandedLocationIds.push(event.panelId);
				this.expandedLocationIds = this.expandedLocationIds.sort();
			}
		}
	}

	preventRowToggle(event) {
		event.stopPropagation();
	}

	downloadCsv() {
		if (!this.subscriptionService.helpers.isGlobalOrIsValid()) {
			this.subscriptionService.helpers.showInvalidSubscriptionModal();
			return;
		}

		let filter: SearchFilter = {
			accounts: [{ accountId: this.authService.data.account.accountId }],
		};
		this.reportService.api.getDevicesCsv(filter).subscribe({
			next: (response) => {
				this.reportService.helpers.saveAsFile(response);
			},
			error: (error) => {
				this.alertMessageService.error('Error getting devices CSV.', error);
			},
		});

		filter.managedDeviceTypes = this.enumService.helpers.getManagedDeviceTypes(false)?.map((t) => t.value);
		this.managedDeviceService.api.getManagedDevicesCsv(filter).subscribe({
			next: (response) => {
				this.reportService.helpers.saveAsFile(response);
			},
			error: (error) => {
				this.alertMessageService.error('Error getting managed devices CSV.', error);
			},
		});
	}

	getOpenAlerts(alertTotals: AlertTotals[], unitId: number) {
		const at: AlertTotals[] = alertTotals.filter(
			(at: AlertTotals) => at.unitId === unitId && (at.status === AlertStatus.New || at.status === AlertStatus.Acknowledged)
		);
		return at.reduce((sum, a) => sum + a.count, 0);
	}

	getCriticalAlerts(alertTotals: AlertTotals[], unitId: number) {
		const criticalIds: number[] = [1, 2, 3, 9, 10, 11, 12, 18];
		const at: AlertTotals[] = alertTotals.filter(
			(at: AlertTotals) =>
				at.unitId === unitId && (at.status === AlertStatus.New || at.status === AlertStatus.Acknowledged) && criticalIds.includes(at.alertTypeId)
		);
		return at.reduce((sum, a) => sum + a.count, 0);
	}
}
