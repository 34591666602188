export enum UnitType {
	Other = 0,
	MPRO = 1,
	ServerSwitchIQ = 2,
	Core = 3,
	Core2G = 4,
	Dell = 5,
	ServerSwitchIQ2G = 6,
	ServerSwitchIQ3G = 7,
}
