import { Injectable } from '@angular/core';
import {
	AuthService,
	CloudApiService,
	RegisterUnitDto,
	SearchFilter,
	SearchFilterResult,
	UnitAndDeviceTotals,
	UnitWarrantyDto,
	UpdateUnitDto,
} from '@razberi-ui/api/cloud-api';
import {
	AccountType,
	CameraStatus,
	CameraStreamStatus,
	DiskType,
	ManagedDevice,
	ManagedDeviceType,
	NetworkConfigurationType,
	RaidArrayStatus,
	RaidArrayType,
	RecordingServerStatus,
	Unit,
	UnitFields,
	UnitStatus,
	UnitType,
	Update,
	UpdateType,
	UserRole,
	VmsService,
	VmsServiceStatus,
	VmsServiceType,
} from '@razberi-ui/core/data-types';
import { UtilitiesService } from '@razberi-ui/shared';
import { NGXLogger } from 'ngx-logger';
import { Observable, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class UnitService {
	constructor(
		private readonly logger: NGXLogger,
		private readonly cloudApiService: CloudApiService,
		private readonly utils: UtilitiesService,
		private readonly authService: AuthService
	) {}

	get api() {
		return {
			getUnits: (searchFilter?: SearchFilter): Observable<SearchFilterResult<Unit>> => {
				return this.cloudApiService.unit.getUnits(searchFilter);
			},
			getUnitsByAccountId: (accountId: number): Observable<Unit[]> => {
				return this.cloudApiService.unit.getUnitsByAccountId(accountId);
			},
			getUnitsByLookup: (lookup: string, filter?: SearchFilter): Observable<SearchFilterResult<Unit>> => {
				return this.cloudApiService.unit.getUnitsByLookup(lookup, filter);
			},
			getAccountsUnits: (accountIds: number[] = null): Observable<Unit[]> => {
				return this.cloudApiService.unit.getAccountsUnits(accountIds);
			},
			getUnit: (accountId: number, unitIdOrKey: string): Observable<Unit> => {
				return this.cloudApiService.unit.getUnit(accountId, unitIdOrKey).pipe(
					map((data) => {
						this.helpers.addStickers(data);
						return data;
					})
				);
			},
			getUpdatesForUnit: (accountId: number, unitId: number): Observable<Update> => {
				return this.cloudApiService.unit.getUpdatesForUnit(accountId, unitId);
			},
			registerUnit: (registerDto: RegisterUnitDto): Observable<void> => {
				return this.cloudApiService.unit.registerUnit(registerDto);
			},
			updateUnit: (accountId: number, unitId: number, updateDto: UpdateUnitDto): Observable<void> => {
				return this.cloudApiService.unit.updateUnit(accountId, unitId, updateDto);
			},
			deleteUnit: (accountId: number, unitId: number): Observable<void> => {
				return this.cloudApiService.unit.deleteUnit(accountId, unitId);
			},
			deleteUnitPermanently: (accountId: number, unitId: number): Observable<void> => {
				return this.cloudApiService.unit.deleteUnitPermanently(accountId, unitId);
			},
			getAccountsVmsServices: (accountIds: number[] = null): Observable<VmsService[]> => {
				return this.cloudApiService.unit.getAccountsVmsServices(accountIds);
			},
			getActivationCode: (accountId: number, unitIdOrKey: string): Observable<string> => {
				return this.cloudApiService.unit.getActivationCode(accountId, unitIdOrKey);
			},
			getUnitFields: (accountId: number, unitId: number): Observable<UnitFields> => {
				return this.cloudApiService.unit.getUnitFields(accountId, unitId);
			},
			deleteUnitFields: (accountId: number, unitId: number): Observable<void> => {
				return this.cloudApiService.unit.deleteUnitFields(accountId, unitId);
			},
			setUnitWarranty: (accountId: number, unitId: number, warrantyDto: UnitWarrantyDto): Observable<void> => {
				return this.cloudApiService.unit.setUnitWarranty(accountId, unitId, warrantyDto);
			},
			getUpdateTypes: (): Observable<UpdateType[]> => {
				return this.cloudApiService.unit.getUpdateTypes();
			},
			getUnitAndDeviceTotals: (accountId: number): Observable<UnitAndDeviceTotals> => {
				return this.cloudApiService.unit.getUnitAndDeviceTotals(accountId);
			},
		};
	}

	get helpers() {
		return {
			addStickers: (unit) => {
				unit.type_c = 'unit';
				if (unit.processors) {
					unit.processors.forEach((p) => (p.type_c = 'processor'));
				}
				if (unit.memory) {
					unit.memory.type_c = 'memory';
				}
				if (unit.gpus) {
					unit.gpus.forEach((g) => (g.type_c = 'gpu'));
				}
				if (unit.disks) {
					unit.disks.forEach((d) => (d.type_c = 'disk'));
				}
				if (unit.volumes) {
					unit.volumes.forEach((v) => (v.type_c = 'volume'));
				}
				if (unit.raidArrays) {
					unit.raidArrays.forEach((r) => (r.type_c = 'raidarray'));
				}
				if (unit.uplink1) {
					unit.uplink1.type_c = 'nic';
				}
				if (unit.uplink2) {
					unit.uplink2.type_c = 'nic';
				}
				if (unit.uplink2Fiber) {
					unit.uplink2Fiber.type_c = 'nic';
				}
				if (unit.sfp1) {
					unit.sfp1.type_c = 'nic';
				}
				if (unit.sfp2) {
					unit.sfp2.type_c = 'nic';
				}
				if (unit.sfp3) {
					unit.sfp3.type_c = 'nic';
				}
				if (unit.sfp4) {
					unit.sfp4.type_c = 'nic';
				}
				if (unit.nics) {
					unit.nics.forEach((n) => (n.type_c = 'nic'));
				}
				if (unit.vmsServices) {
					unit.vmsServices.forEach((v) => {
						v.type_c = 'vmsservice';
						if (v.recordingServers) v.recordingServers.forEach((r) => (r.type_c = 'recordingserver'));
						if (v.cameras) v.cameras.forEach((c) => (c.type_c = 'camera'));
						if (v.cameraStreams) v.cameraStreams.forEach((cs) => (cs.type_c = 'camerastream'));
					});
				}
				if (unit.managedDevices?.length > 0) {
					unit.managedDevices.filter((md) => md.type === ManagedDeviceType.Agent)?.forEach((md) => (md.type_c = 'agent'));
					unit.managedDevices.filter((md) => md.type === ManagedDeviceType.InternalSwitch)?.forEach((md) => (md.type_c = 'internalswitch'));
					unit.managedDevices.filter((md) => md.type === ManagedDeviceType.EndpointDefender)?.forEach((md) => (md.type_c = 'epd'));
					unit.managedDevices.filter((md) => md.type === ManagedDeviceType.SnmpDevice)?.forEach((md) => (md.type_c = 'snmp'));
					unit.managedDevices.filter((md) => md.type === ManagedDeviceType.CngeSwitch)?.forEach((md) => (md.type_c = 'cnge'));
				}
                if (unit.powerSupplies) {
					unit.powerSupplies.forEach((d) => (d.type_c = 'powersupply'));
				}
				return unit;
			},
			userCanEditUnit: (): boolean => {
				return (
					(this.authService.data.account.type === AccountType.Customer && this.authService.helpers.userHasRole(UserRole.Administrator) == true) ||
					this.authService.data.account.type === AccountType.Global ||
					this.authService.data.account.type === AccountType.Provider
				);
			},
		};
	}

	get formatters() {
		return {
			asUnitType: (unitType: UnitType): string => {
				// return 2G as original 1G type
				if (unitType === UnitType.Core2G) return UnitType[UnitType.Core];
				if (unitType === UnitType.ServerSwitchIQ2G) return UnitType[UnitType.ServerSwitchIQ];
				return UnitType[unitType];
			},
			asDiskBay: (bay: number): string => {
				return bay < 0 ? 'internal ' + (-1 - bay) : bay.toString();
			},
		};
	}

	get transform() {
		return {
			transformNode: (selNode) => {
				// the format we need for displaying purpose
				var transNode = {
					Name: selNode.detail.name,
					Identifier: selNode.detail.unitId + selNode.type + selNode.key,
					UnitId: selNode.detail.unitId,
					TypeLabel: selNode.typeLabel ?? null,
					Collapsed: selNode.collapsed ?? false,
					Properties: [],
					Children: [],
					IsParent: selNode.isParent,
					Type: selNode.type,
					Status: selNode.detail.status,
				};

				// find value for properties and children fields
				var properties = [];
				var children = [];
				for (var name in selNode.detail) {
					var value = selNode.detail[name];
					if (this.utils.helpers.isPlainObject(value) && !Array.isArray(value) && selNode.isParent) {
						// this is an object field of a parent -> we are looking at a child
						var childObj = {
							Name: value.name,
							Identifier: 'not_needed',
							UnitId: value.unitId,
							Properties: [],
							Children: [],
							IsParent: false,
							Type: value.type,
							Status: value.status,
						};
						var childProperties = [];
						for (var childName in value) {
							var childValue = value[childName];
							if (!this.utils.helpers.isPlainObject(childValue) && this.transform.fieldNeeded(value.type_c, childName)) {
								childProperties.push({
									Name: this.transform.fieldLabel(value.type_c, childName),
									FormattedValue: this.transform.formattedValue(value.type_c, childName, childValue),
								});
							}
							// don't go further for the child object of the child
						}
						childObj.Properties = childProperties; // we don't need to care about its children
						children.push(childObj);
					} else if (Array.isArray(value) && selNode.isParent) {
						// treat each element of the array as a separate child
						value.forEach((element) => {
							var childObj = {
								Name: element.name,
								Identifier: 'not_needed',
								UnitId: element.unitId,
								Properties: [],
								Children: [],
								IsParent: false,
								Type: element.type_c,
								Status: element.status,
							};
							var childProperties = [];
							for (var childName in element) {
								var childValue = element[childName];
								if (!this.utils.helpers.isPlainObject(childValue) && this.transform.fieldNeeded(element.type_c, childName)) {
									childProperties.push({
										Name: this.transform.fieldLabel(element.type_c, childName),
										FormattedValue: this.transform.formattedValue(element.type_c, childName, childValue),
									});
								}
								// don't go further for the child object of the child
							}
							childObj.Properties = childProperties; // we don't need to care about its children
							children.push(childObj);
						});
					} else {
						// regular property, add it as 'name-value' pair
						if (this.transform.fieldNeeded(selNode.detail.type_c, name))
							properties.push({
								Name: this.transform.fieldLabel(selNode.detail.type_c, name),
								FormattedValue: this.transform.formattedValue(selNode.detail.type_c, name, value),
							});
					}
				}

				// sort properties in same order as the fieldMapping object
				let sortedProps = [];
				let fields = this.mapping.fieldMapping()[selNode.detail.type_c.toLowerCase()];
				for (let name in fields) {
					let i = properties.findIndex((o) => o.Name === fields[name].label || o.Name === name);
					if (i >= 0) sortedProps.push(properties[i]);
				}

				transNode.Properties = sortedProps;
				transNode.Children = children;
				return transNode;
			},
			fieldNeeded: (deviceType, fieldName) => {
				var deviceMapping = deviceType && this.mapping.fieldMapping()[deviceType.toLowerCase()];
				return deviceMapping && deviceMapping[fieldName];
			},
			// get the display text for a field of a device type
			fieldLabel: (deviceType, fieldName) => {
				var fieldLabel, deviceMapping;
				deviceMapping = this.mapping.fieldMapping()[deviceType.toLowerCase()];
				if (deviceMapping) fieldLabel = deviceMapping[fieldName].label;
				return fieldLabel ? fieldLabel : fieldName;
			},
			// get the formatted text for the field value of a device type
			formattedValue: (deviceType, fieldName, fieldValue) => {
				var formattedValue, deviceMapping;
				deviceMapping = this.mapping.fieldMapping()[deviceType.toLowerCase()];
				if (deviceMapping) formattedValue = deviceMapping[fieldName].fnFormat(fieldValue);
				return formattedValue ? formattedValue : fieldValue;
			},
			findChildObject: (parentObj, type, key) => {
				var target;
				switch (type.toLowerCase()) {
					case 'processor':
						for (var i = 0; i < parentObj.processors.length; i++) {
							if (parentObj.processors[i].processorKey === key) {
								target = parentObj.processors[i];
								break;
							}
						}
						break;
					case 'memory':
						target = parentObj.memory;
						break;
					case 'gpu':
						for (var i = 0; i < parentObj.gpus.length; i++) {
							if (parentObj.gpus[i].gpuKey === key) {
								target = parentObj.gpus[i];
								break;
							}
						}
						break;
					case 'sfp1':
						target = parentObj.sfp1;
						break;
					case 'sfp2':
						target = parentObj.sfp2;
						break;
					case 'sfp3':
						target = parentObj.sfp3;
						break;
					case 'sfp4':
						target = parentObj.sfp4;
						break;
					case 'uplink1':
						target = parentObj.uplink1;
						break;
					case 'uplink2':
						target = parentObj.uplink2;
						break;
					case 'uplink2fiber':
						target = parentObj.uplink2Fiber;
						break;
					case 'switch':
					case 'internalswitch':
						target = parentObj.managedDevices?.find((md) => md.type === ManagedDeviceType.InternalSwitch);
						break;
					case 'disk':
						for (var i = 0; i < parentObj.disks.length; i++) {
							if (parentObj.disks[i].diskKey === key) {
								target = parentObj.disks[i];
								break;
							}
						}
						break;
					case 'volume':
						for (var i = 0; i < parentObj.volumes.length; i++) {
							if (parentObj.volumes[i].volumeKey === key) {
								target = parentObj.volumes[i];
								break;
							}
						}
						break;
					case 'raidarray':
						for (var i = 0; i < parentObj.raidArrays.length; i++) {
							if (parentObj.raidArrays[i].raidArrayKey === key) {
								target = parentObj.raidArrays[i];
								break;
							}
						}
						break;
					case 'endpointdefender':
						for (var i = 0; i < parentObj.endpointDefenders.length; i++) {
							if (parentObj.endpointDefenders[i].endpointDefenderKey === key) {
								target = parentObj.endpointDefenders[i];
								break;
							}
						}
						break;
					case 'vmsservice':
						this.logger?.log('find vms', parentObj, key);
						for (var i = 0; i < parentObj.vmsServices.length; i++) {
							if (parentObj.vmsServices[i].vmsServiceKey === key) {
								target = parentObj.vmsServices[i];
								break;
							}
						}
						break;
					case 'recordingserver':
						this.logger?.log('find rs', parentObj, key);
						for (var i = 0; i < parentObj.vmsServices.length; i++) {
							let vmsService: VmsService = parentObj.vmsServices[i];
							let recServer = vmsService.recordingServers.find((rs) => rs.recordingServerKey === key);
							if (recServer) {
								target = recServer;
								break;
							}
						}
						break;
					case 'camera':
						this.logger?.log('find cam', parentObj, key);
						for (var i = 0; i < parentObj.vmsServices.length; i++) {
							let vmsService: VmsService = parentObj.vmsServices[i];
							let cam = vmsService.cameras.find((c) => c.cameraKey === key);
							if (cam) {
								target = cam;
								break;
							}
						}
						break;
					case 'camerastream':
						this.logger?.log('find cs', parentObj, key);
						for (var i = 0; i < parentObj.vmsServices.length; i++) {
							let vmsService: VmsService = parentObj.vmsServices[i];
							let camStream = vmsService.cameraStreams.find((cs) => cs.cameraStreamId === key);
							if (camStream) {
								target = camStream;
								break;
							}
						}
						break;
					case 'nic':
						for (var i = 0; i < parentObj.nics.length; i++) {
							if (parentObj.nics[i].nicKey === key) {
								target = parentObj.nics[i];
								break;
							}
						}
						break;
                    case 'powersupply':
						for (var i = 0; i < parentObj.powerSupplies.length; i++) {
							if (parentObj.powerSupplies[i].powerSupplyKey === key) {
								target = parentObj.powerSupplies[i];
								break;
							}
						}
						break;
					default:
						break;
				}
				return target;
			},
			buildChildData: (data) => {
				let childData = [];

				if (data.processors?.length > 0) childData = childData.concat(this.mapping.mapProcessors(data.processors));
				if (data.memory) childData.push(this.mapping.mapMemory(data.memory));
				if (data.gpus?.length > 0) childData = childData.concat(this.mapping.mapGpus(data.gpus));
				if (data.disks?.length > 0) childData = childData.concat(this.mapping.mapDisks(data.disks));
				if (data.volumes?.length > 0) childData = childData.concat(this.mapping.mapVolumes(data.volumes));
				if (data.raidArrays?.length > 0) childData = childData.concat(this.mapping.mapRaidArrays(data.raidArrays));
				if (data.sfp1) childData.push(this.mapping.mapSfp1(data.sfp1));
				if (data.sfp2) childData.push(this.mapping.mapSfp2(data.sfp2));
				if (data.sfp3) childData.push(this.mapping.mapSfp3(data.sfp3));
				if (data.sfp4) childData.push(this.mapping.mapSfp4(data.sfp4));
				if (data.uplink1) childData.push(this.mapping.mapUplink1(data.uplink1));
				if (data.uplink2) childData.push(this.mapping.mapUplink2(data.uplink2));
				if (data.uplink2Fiber) childData.push(this.mapping.mapUplink2Fiber(data.uplink2Fiber));
				if (data.nics?.length > 0) childData = childData.concat(this.mapping.mapNICs(data.nics));
				if (data.managedDevices?.length > 0) childData = childData.concat(this.mapping.mapManagedDeviceInternalSwitch(data.managedDevices));
				if (data.endpointDefenders?.length > 0) childData = childData.concat(this.mapping.mapEndpointDefenders(data.endpointDefenders));
				if (data.vmsServices?.length > 0) childData = childData.concat(this.mapping.mapVmsServices(data.vmsServices));
                if (data.powerSupplies?.length > 0) childData = childData.concat(this.mapping.mapPowerSupplies(data.powerSupplies));

				this.logger?.log(data, childData);
				return childData;
			},
		};
	}

	get mapping() {
		return {
			fieldMapping: () => {
				return {
					unit: {
						name: { label: 'Name', fnFormat: this.utils.formatters.asIs },
						status: { label: 'Status', fnFormat: (s) => UnitStatus[s] },
						manufacturer: { label: 'Manufacturer', fnFormat: this.utils.formatters.asIs },
						modelName: { label: 'Model Name', fnFormat: this.utils.formatters.asIs },
						serialNumber: { label: 'Serial Number', fnFormat: this.utils.formatters.asIs },
						type: { label: 'Type', fnFormat: this.formatters.asUnitType },
						monitor: { label: 'Version', fnFormat: this.utils.formatters.asIs },
						machineName: { label: 'Machine Name', fnFormat: this.utils.formatters.asIs },
						operatingSystem: { label: 'Operating System', fnFormat: this.utils.formatters.asIs },
						assemblyTimestamp: { label: 'OS Install Date', fnFormat: this.utils.formatters.asLocalTime },
						timeZone: { label: 'Time Zone', fnFormat: this.utils.formatters.asIs },
						lastBoot: { label: 'Last Boot', fnFormat: this.utils.formatters.asLocalTime },
						bios: { label: 'BIOS', fnFormat: this.utils.formatters.asIs },
						hasCylance: { label: 'Malware Protection', fnFormat: (b) => (b ? 'Yes' : 'No') },
						vms: { label: 'VMS', fnFormat: this.utils.formatters.asIs },
						location: { label: 'Location', fnFormat: this.utils.formatters.asIs },
						licenseName: { label: 'License', fnFormat: this.utils.formatters.asIs },
						licenseCustomer: { label: 'License Customer', fnFormat: this.utils.formatters.asIs },
						licenseDate: { label: 'License Date', fnFormat: this.utils.formatters.asLocalDate },
						licenseExpiration: { label: 'License Expiration', fnFormat: this.utils.formatters.asLocalDate },
						licenseFeatures: { label: 'License Features', fnFormat: this.utils.formatters.asIs },
						licensePortsUsed: { label: 'Licensed Ports Used', fnFormat: this.utils.formatters.asIs },
						serviceTag: { label: 'Service Tag', fnFormat: this.utils.formatters.asIs },
						manufactureTimestamp: { label: 'Manufacture Date', fnFormat: this.utils.formatters.asLocalDate },
						warrantyExpiration: { label: 'Warranty Expiration', fnFormat: this.utils.formatters.asUtcDate },
						applianceDefenseExpiration: { label: 'ApplianceDefense Expiration', fnFormat: this.utils.formatters.asUtcDate },
						pingTimestamp: { label: 'Checked-In', fnFormat: this.utils.formatters.asLocalTime },
						createTimestamp: { label: 'Registered', fnFormat: this.utils.formatters.asLocalTime },
						updateTimestamp: { label: 'Updated', fnFormat: this.utils.formatters.asLocalTime },
					},
					processor: {
						name: { label: 'Name', fnFormat: this.utils.formatters.asIs },
						clockSpeed: { label: 'Clock Speed', fnFormat: (s) => (s ? s + ' MHz' : s) },
						physicalCores: { label: 'Physical Cores', fnFormat: this.utils.formatters.asIs },
						logicalCores: { label: 'Logical Cores', fnFormat: this.utils.formatters.asIs },
					},
					memory: {
						name: { label: 'Name', fnFormat: this.utils.formatters.asIs },
						capacity: { label: 'Capacity', fnFormat: this.utils.formatters.asMemoryCapacity },
						quantity: { label: 'Quantity', fnFormat: this.utils.formatters.asIs },
					},
					gpu: {
						name: { label: 'Name', fnFormat: this.utils.formatters.asIs },
						memory: { label: 'Memory', fnFormat: this.utils.formatters.asMemoryCapacity },
						resolution: { label: 'Resolution', fnFormat: this.utils.formatters.asIs },
					},
					disk: {
						name: { label: 'Name', fnFormat: this.utils.formatters.asIs },
						bay: { label: 'Bay', fnFormat: this.formatters.asDiskBay },
						type: { label: 'Type', fnFormat: (t) => DiskType[t] },
						capacity: { label: 'Capacity', fnFormat: this.utils.formatters.asMemoryCapacity },
						modelName: { label: 'Model Name', fnFormat: this.utils.formatters.asIs },
						serialNumber: { label: 'Serial Number', fnFormat: this.utils.formatters.asIs },
						firmware: { label: 'Firmware', fnFormat: this.utils.formatters.asIs },
					},
					volume: {
						name: { label: 'Name', fnFormat: this.utils.formatters.asIs },
						driveLetter: { label: 'Drive Letter', fnFormat: this.utils.formatters.asIs },
						label: { label: 'Label', fnFormat: this.utils.formatters.asIs },
						capacity: { label: 'Capacity', fnFormat: this.utils.formatters.asMemoryCapacity },
						freeSpace: { label: 'Free Space', fnFormat: this.utils.formatters.asMemoryCapacity },
					},
					raidarray: {
						name: { label: 'Name', fnFormat: this.utils.formatters.asIs },
						controller: { label: 'Controller', fnFormat: this.utils.formatters.asIs },
						type: { label: 'Type', fnFormat: (t) => RaidArrayType[t].toUpperCase().replace('RAID', 'RAID ') },
						capacity: { label: 'Capacity', fnFormat: this.utils.formatters.asMemoryCapacity },
						status: { label: 'Status', fnFormat: (s) => RaidArrayStatus[s] },
					},
					nic: {
						connected: { label: 'Connected', fnFormat: this.utils.formatters.asBoolean },
						adapterName: { label: 'Adapter Name', fnFormat: this.utils.formatters.asIs },
						speed: { label: 'Speed', fnFormat: this.utils.formatters.asSpeed },
						macAddress: { label: 'MAC Address', fnFormat: this.utils.formatters.asMacAddress },
						ipAddress: { label: 'IP Address', fnFormat: this.utils.formatters.asIpAddress },
						subnetMask: { label: 'Subnet Mask', fnFormat: this.utils.formatters.asIpAddress },
						gateway: { label: 'Gateway', fnFormat: this.utils.formatters.asIpAddress },
						dnsServer: { label: 'DNS Server', fnFormat: this.utils.formatters.asIpAddress },
						networkConfigurationType: { label: 'ConnectionType', fnFormat: (t) => NetworkConfigurationType[t] },
					},
					switch: {
						name: { label: 'Name', fnFormat: this.utils.formatters.asIs },
						ports: { label: 'Ports', fnFormat: this.utils.formatters.asIs },
						firmware: { label: 'Firmware', fnFormat: this.utils.formatters.asIs },
						macAddress: { label: 'MAC Address', fnFormat: this.utils.formatters.asMacAddress },
						ipAddress: { label: 'IP Address', fnFormat: this.utils.formatters.asIpAddress },
						subnetMask: { label: 'Subnet Mask', fnFormat: this.utils.formatters.asIpAddress },
						gateway: { label: 'Gateway', fnFormat: this.utils.formatters.asIpAddress },
						dnsServer: { label: 'DNS Server', fnFormat: this.utils.formatters.asIpAddress },
						connectionType: { label: 'Connection Type', fnFormat: this.utils.formatters.asIs },
						maximumPoEPower: { label: 'Maximum PoE Power', fnFormat: this.utils.formatters.asIs },
						dhcpServerEnabled: { label: 'DHCP Server Enabled', fnFormat: this.utils.formatters.asBoolean },
						firmwareLicenseName: { label: 'Firmware License', fnFormat: this.utils.formatters.asIs },
						firmwareLicenseCustomer: { label: 'Firmware License Customer', fnFormat: this.utils.formatters.asIs },
						firmwareLicenseDate: { label: 'Firmware License Date', fnFormat: this.utils.formatters.asLocalDate },
						firmwareLicenseExpiration: { label: 'Firmware License Expiration', fnFormat: this.utils.formatters.asLocalDate },
						firmwareLicenseFeatures: { label: 'Firmware License Features', fnFormat: this.utils.formatters.asIs },
						cameraDefenseEnabled: { label: 'CameraDefense Enabled', fnFormat: this.utils.formatters.asBoolean },
						cameraHealthEnabled: { label: 'CameraHealth Enabled', fnFormat: this.utils.formatters.asBoolean },
						location: { label: 'Location', fnFormat: this.utils.formatters.asIs },
						isLicensed: { label: 'Monitor Licensed', fnFormat: this.utils.formatters.asBoolean },
					},
					endpointdefender: {
						name: { label: 'Name', fnFormat: this.utils.formatters.asIs },
						status: { label: 'Status', fnFormat: this.utils.formatters.asIs },
						manufacturer: { label: 'Manufacturer', fnFormat: this.utils.formatters.asIs },
						modelName: { label: 'Model Name', fnFormat: this.utils.formatters.asIs },
						serialNumber: { label: 'Serial Number', fnFormat: this.utils.formatters.asIs },
						ports: { label: 'Ports', fnFormat: this.utils.formatters.asIs },
						firmware: { label: 'Firmware', fnFormat: this.utils.formatters.asIs },
						macAddress: { label: 'MAC Address', fnFormat: this.utils.formatters.asMacAddress },
						ipAddress: { label: 'IP Address', fnFormat: this.utils.formatters.asIpAddress },
						subnetMask: { label: 'Subnet Mask', fnFormat: this.utils.formatters.asIpAddress },
						gateway: { label: 'Gateway', fnFormat: this.utils.formatters.asIpAddress },
						dnsServer: { label: 'DNS Server', fnFormat: this.utils.formatters.asIpAddress },
						connectionType: { label: 'Connection Type', fnFormat: this.utils.formatters.asIs },
						maximumPoEPower: { label: 'Maximum PoE Power', fnFormat: this.utils.formatters.asIs },
						dhcpServerEnabled: { label: 'DHCP Server Enabled', fnFormat: this.utils.formatters.asBoolean },
						firmwareLicenseName: { label: 'Firmware License', fnFormat: this.utils.formatters.asIs },
						firmwareLicenseCustomer: { label: 'Firmware License Customer', fnFormat: this.utils.formatters.asIs },
						firmwareLicenseDate: { label: 'Firmware License Date', fnFormat: this.utils.formatters.asLocalDate },
						firmwareLicenseExpiration: { label: 'Firmware License Expiration', fnFormat: this.utils.formatters.asLocalDate },
						firmwareLicenseFeatures: { label: 'Firmware License Features', fnFormat: this.utils.formatters.asIs },
						cameraDefenseEnabled: { label: 'CameraDefense Enabled', fnFormat: this.utils.formatters.asBoolean },
						cameraHealthEnabled: { label: 'CameraHealth Enabled', fnFormat: this.utils.formatters.asBoolean },
						registerTimestamp: { label: 'Registered', fnFormat: this.utils.formatters.asLocalTime },
						location: { label: 'Location', fnFormat: this.utils.formatters.asIs },
						isLicensed: { label: 'Monitor Licensed', fnFormat: this.utils.formatters.asBoolean },
					},
					vmsservice: {
						name: { label: 'Name', fnFormat: this.utils.formatters.asIs },
						description: { label: 'Description', fnFormat: this.utils.formatters.asIs },
						status: { label: 'Status', fnFormat: (s) => VmsServiceStatus[s] },
						statusMessage: { label: 'Status Message', fnFormat: this.utils.formatters.asIs },
						vmsServiceType: { label: 'VMS Service Type', fnFormat: (s) => VmsServiceType[s] },
						serverUri: { label: 'Server URI', fnFormat: this.utils.formatters.asIs },
						serverType: { label: 'Server Type', fnFormat: this.utils.formatters.asIs },
						hostname: { label: 'Hostname', fnFormat: this.utils.formatters.asIs },
						username: { label: 'Username', fnFormat: this.utils.formatters.asIs },
						domainName: { label: 'Domain Name', fnFormat: this.utils.formatters.asIs },
						machineName: { label: 'Machine Name', fnFormat: this.utils.formatters.asIs },
						timezone: { label: 'Timezone', fnFormat: this.utils.formatters.asIs },
						connectionState: { label: 'Connection State', fnFormat: this.utils.formatters.asIs },
						productName: { label: 'Product Name', fnFormat: this.utils.formatters.asIs },
						productVersion: { label: 'Product Version', fnFormat: this.utils.formatters.asIs },
						licenseSlc: { label: 'License SLC', fnFormat: this.utils.formatters.asIs },
						licenseExpiration: { label: 'License Expiration', fnFormat: this.utils.formatters.asLocalTime },
						handshakeTimestamp: { label: 'Handshake Timestamp', fnFormat: this.utils.formatters.asLocalTime },
						createTimestamp: { label: 'Created', fnFormat: this.utils.formatters.asLocalTime },
						updateTimestamp: { label: 'Updated', fnFormat: this.utils.formatters.asLocalTime },
					},
					recordingserver: {
						name: { label: 'Name', fnFormat: this.utils.formatters.asIs },
						description: { label: 'Description', fnFormat: this.utils.formatters.asIs },
						status: { label: 'Status', fnFormat: (s) => RecordingServerStatus[s] },
						statusMessage: { label: 'Status Message', fnFormat: this.utils.formatters.asIs },
						hostname: { label: 'Hostname', fnFormat: this.utils.formatters.asIs },
						ipAddress: { label: 'IP Address', fnFormat: this.utils.formatters.asIpAddress },
						webServerUri: { label: 'Web Server URI', fnFormat: this.utils.formatters.asIs },
						enabled: { label: 'Enabled', fnFormat: this.utils.formatters.asBoolean },
						timezone: { label: 'Timezone', fnFormat: this.utils.formatters.asIs },
						storageCapacity: { label: 'Storage Capacity', fnFormat: this.utils.formatters.asMemoryCapacity },
						storageUsed: { label: 'Storage Used', fnFormat: this.utils.formatters.asMemoryCapacity },
						attachState: { label: 'Attach State', fnFormat: this.utils.formatters.asIs },
						connectionState: { label: 'Connection State', fnFormat: this.utils.formatters.asIs },
						initialOutage: { label: 'Initial Outage', fnFormat: this.utils.formatters.asLocalTime },
						createTimestamp: { label: 'Created', fnFormat: this.utils.formatters.asLocalTime },
						updateTimestamp: { label: 'Updated', fnFormat: this.utils.formatters.asLocalTime },
					},
					camera: {
						name: { label: 'Name', fnFormat: this.utils.formatters.asIs },
						status: { label: 'Status', fnFormat: (s) => CameraStatus[s] },
						statusMessage: { label: 'Status Message', fnFormat: this.utils.formatters.asIs },
						modelName: { label: 'Model Name', fnFormat: this.utils.formatters.asIs },
						address: { label: 'Address', fnFormat: this.utils.formatters.asIs },
						username: { label: 'Username', fnFormat: this.utils.formatters.asIs },
						channel: { label: 'Channel', fnFormat: this.utils.formatters.asIs },
						enabled: { label: 'Enabled', fnFormat: this.utils.formatters.asBoolean },
						recordingEnabled: { label: 'Recording Enabled', fnFormat: this.utils.formatters.asBoolean },
						recordingFramerate: { label: 'Recording Framerate', fnFormat: this.utils.formatters.asIs },
						macAddress: { label: 'MAC Address', fnFormat: this.utils.formatters.asMacAddress },
						serialNumber: { label: 'Serial Number', fnFormat: this.utils.formatters.asIs },
						firmwareVersion: { label: 'Firmware Version', fnFormat: this.utils.formatters.asIs },
						productId: { label: 'Product Id', fnFormat: this.utils.formatters.asIs },
						enabledState: { label: 'Enabled State', fnFormat: this.utils.formatters.asIs },
						startedState: { label: 'Started State', fnFormat: this.utils.formatters.asIs },
						recordingState: { label: 'Recording State', fnFormat: this.utils.formatters.asIs },
						errorState: { label: 'Error State', fnFormat: this.utils.formatters.asIs },
						storageBytesUsedState: {
							label: 'Storage Space Used',
							fnFormat: (s) => (s != null ? this.utils.formatters.asMemoryCapacity(s) : this.utils.formatters.asIs(s)),
						},
						createTimestamp: { label: 'Created', fnFormat: this.utils.formatters.asLocalTime },
						updateTimestamp: { label: 'Updated', fnFormat: this.utils.formatters.asLocalTime },
					},
					camerastream: {
						name: { label: 'Name', fnFormat: this.utils.formatters.asIs },
						status: { label: 'Status', fnFormat: (s) => CameraStreamStatus[s] },
						statusMessage: { label: 'Status Message', fnFormat: this.utils.formatters.asIs },
						recordEnabled: { label: 'Record Enabled', fnFormat: this.utils.formatters.asBoolean },
						liveDefault: { label: 'Live Default', fnFormat: this.utils.formatters.asIs },
						liveMode: { label: 'Live Mode', fnFormat: this.utils.formatters.asIs },
						profileName: { label: 'Profile Name', fnFormat: this.utils.formatters.asIs },
						codec: { label: 'Codec', fnFormat: this.utils.formatters.asIs },
						resolution: { label: 'Resolution', fnFormat: this.utils.formatters.asIs },
						bitrate: { label: 'Bitrate', fnFormat: this.utils.formatters.asIs },
						framesPerSecond: { label: 'Frames Per Second', fnFormat: this.utils.formatters.asIs },
						maxGopSize: { label: 'Max GOP Size', fnFormat: this.utils.formatters.asIs },
						streamingMode: { label: 'Streaming Mode', fnFormat: this.utils.formatters.asIs },
						recordingStreamState: { label: 'Recording Stream State', fnFormat: this.utils.formatters.asIs },
						liveStreamState: { label: 'Live Stream State', fnFormat: this.utils.formatters.asIs },
						imageResolutionWidthState: { label: 'Image Resolution Width State', fnFormat: this.utils.formatters.asIs },
						imageResolutionHeightState: { label: 'Image Resolution Height State', fnFormat: this.utils.formatters.asIs },
						videoFormatState: { label: 'Video Format State', fnFormat: this.utils.formatters.asIs },
						fpsState: { label: 'FPS State', fnFormat: this.utils.formatters.asIs },
						bpsState: { label: 'BPS State', fnFormat: this.utils.formatters.asIs },
						createTimestamp: { label: 'Created', fnFormat: this.utils.formatters.asLocalTime },
						updateTimestamp: { label: 'Updated', fnFormat: this.utils.formatters.asLocalTime },
					},
					internalswitch: {
						name: { label: 'Name', fnFormat: this.utils.formatters.asIs },
						ports: { label: 'Ports', fnFormat: this.utils.formatters.asIs },
						firmware: { label: 'Firmware', fnFormat: this.utils.formatters.asIs },
						macAddress: { label: 'MAC Address', fnFormat: this.utils.formatters.asMacAddress },
						ipAddress: { label: 'IP Address', fnFormat: this.utils.formatters.asIpAddress },
						subnetMask: { label: 'Subnet Mask', fnFormat: this.utils.formatters.asIpAddress },
						gateway: { label: 'Gateway', fnFormat: this.utils.formatters.asIpAddress },
						dnsServer: { label: 'DNS Server', fnFormat: this.utils.formatters.asIpAddress },
						connectionType: { label: 'Connection Type', fnFormat: this.utils.formatters.asIs },
						maximumPoEPower: { label: 'Maximum PoE Power', fnFormat: this.utils.formatters.asIs },
						poeConsumptionPower: { label: 'PoE Consumption Power', fnFormat: this.utils.formatters.asIs },
						dhcpServerEnabled: { label: 'DHCP Server Enabled', fnFormat: this.utils.formatters.asBoolean },
						firmwareLicenseName: { label: 'Firmware License', fnFormat: this.utils.formatters.asIs },
						firmwareLicenseCustomer: { label: 'Firmware License Customer', fnFormat: this.utils.formatters.asIs },
						firmwareLicenseDate: { label: 'Firmware License Date', fnFormat: this.utils.formatters.asLocalDate },
						firmwareLicenseExpiration: { label: 'Firmware License Expiration', fnFormat: this.utils.formatters.asLocalDate },
						firmwareLicenseFeatures: { label: 'Firmware License Features', fnFormat: this.utils.formatters.asIs },
						cameraDefenseEnabled: { label: 'CameraDefense Enabled', fnFormat: this.utils.formatters.asBoolean },
						cameraHealthEnabled: { label: 'CameraHealth Enabled', fnFormat: this.utils.formatters.asBoolean },
						location: { label: 'Location', fnFormat: this.utils.formatters.asIs },
						isLicensed: { label: 'Monitor Licensed', fnFormat: this.utils.formatters.asBoolean },
					},
                    powersupply: {
						name: { label: 'Name', fnFormat: this.utils.formatters.asIs },
						bay: { label: 'Bay', fnFormat: this.utils.formatters.asIs },
						manufacturer: { label: 'Manufacturer', fnFormat: this.utils.formatters.asIs },
						modelName: { label: 'Model Name', fnFormat: this.utils.formatters.asIs },
						serialNumber: { label: 'Serial Number', fnFormat: this.utils.formatters.asIs },
						partNumber: { label: 'Part Number', fnFormat: this.utils.formatters.asIs },
                        lineStatus: { label: 'Line Status', fnFormat: this.utils.formatters.asIs },
                        outputWatts: { label: 'Rated Watts', fnFormat: this.utils.formatters.asIs },
                        inputVoltage: { label: 'Input Voltage', fnFormat: this.utils.formatters.asIs },
                        inputCurrent: { label: 'Input Current', fnFormat: (source) => source / 1000 },
                        powerConsumption: { label: 'Output Watts', fnFormat: this.utils.formatters.asIs }
					},
				};
			},
			mapParentUnits: (data) => {
				return data.map(function (u) {
					return {
						name: u.name,
						model: u.modelName,
						serialNumber: u.serialNumber,
						location: u.location,
						status: u.status,
						type: 'Unit',
						isParent: true,
						expanded: false,
						key: u.unitKey,
						unitId: u.unitId,
					};
				});
			},
			// map Units collection (parent level) into a standard displayble data collection
			mapProcessors: (data) => {
				return data.map(function (p) {
					return {
						name: p.name,
						model: null,
						serialNumber: null,
						location: null,
						status: p.status,
						type: 'Processor',
						isParent: false,
						expanded: false,
						key: p.processorKey,
						unitId: p.unitId,
					};
				});
			},
			// map Units collection (parent level) into a standard displayble data collection
			mapMemory: (data) => {
				return {
					name: data.name,
					model: null,
					serialNumber: null,
					location: null,
					status: data.status,
					type: 'Memory',
					isParent: false,
					expanded: false,
					key: data.memoryKey,
					unitId: data.unitId,
				};
			},
			// map Units collection (parent level) into a standard displayble data collection
			mapGpus(data) {
				return data.map(function (u) {
					return {
						name: u.name,
						model: null,
						serialNumber: null,
						location: null,
						status: u.status,
						type: 'Gpu',
						isParent: false,
						expanded: false,
						key: u.gpuKey,
						unitId: u.unitId,
					};
				});
			},
			// map Units collection (parent level) into a standard displayble data collection
			mapDisks: (data) => {
				return data
					.map(function (u) {
						return {
							name: u.name,
							model: u.modelName,
							serialNumber: u.serialNumber,
							location: null,
							status: u.status,
							bay: u.bay,
							type: 'Disk',
							isParent: false,
							expanded: false,
							key: u.diskKey,
							unitId: u.unitId,
						};
					})
					?.sort((d1, d2) => (d1.bay > d2.bay ? 1 : -1));
			},
			// map Units collection (parent level) into a standard displayble data collection
			mapVolumes: (data) => {
				return data
					.map(function (u) {
						return {
							name: u.name,
							model: null,
							serialNumber: null,
							location: null,
							status: u.status,
							type: 'Volume',
							isParent: false,
							expanded: false,
							key: u.volumeKey,
							unitId: u.unitId,
						};
					})
					?.sort((d1, d2) => (d1.name > d2.name ? 1 : -1));
			},
			// map Units collection (parent level) into a standard displayble data collection
			mapRaidArrays: (data) => {
				return data
					.map(function (u) {
						return {
							name: u.name,
							model: null,
							serialNumber: null,
							location: null,
							status: u.status,
							type: 'RaidArray',
							isParent: false,
							expanded: false,
							key: u.raidArrayKey,
							unitId: u.unitId,
						};
					})
					?.sort((d1, d2) => (d1.name > d2.name ? 1 : -1));
			},
			// map Units collection (parent level) into a standard displayble data collection
			mapNic: (data, type) => {
				return {
					name: data.name,
					model: null,
					serialNumber: null,
					location: null,
					status: data.status,
					type: type,
					isParent: false,
					expanded: false,
					key: data.nicKey,
					unitId: data.unitId,
				};
			},
			// map Units collection (parent level) into a standard displayble data collection
			mapNICs: (data) => {
				return data
					.map((u) => {
						return this.mapping.mapNic(u, 'NIC');
					})
					?.sort((d1, d2) => (d1.name > d2.name ? 1 : -1));
			},
			mapSfp1: (data) => {
				return this.mapping.mapNic(data, 'Sfp1');
			},
			mapSfp2: (data) => {
				return this.mapping.mapNic(data, 'Sfp2');
			},
			mapSfp3: (data) => {
				return this.mapping.mapNic(data, 'Sfp3');
			},
			mapSfp4: (data) => {
				return this.mapping.mapNic(data, 'Sfp4');
			},
			mapUplink1: (data) => {
				return this.mapping.mapNic(data, 'Uplink1');
			},
			mapUplink2: (data) => {
				return this.mapping.mapNic(data, 'Uplink2');
			},
			mapUplink2Fiber: (data) => {
				return this.mapping.mapNic(data, 'Uplink2Fiber');
			},
			// map Units collection (parent level) into a standard displayble data collection
			mapSwitch: (data) => {
				return {
					name: data.name,
					model: data.modelName,
					serialNumber: data.serialNumber,
					location: data.location,
					status: data.status,
					cameraDefenseEnabled: data.cameraDefenseEnabled,
					licenseExpiration: !data.isLicensed
						? '0001-01-01T00:00:00Z'
						: !data.firmwareLicenseExpiration
						? '9999-12-31T23:59:59Z'
						: data.firmwareLicenseExpiration, // min = unlicensed, max = no exp
					type: 'Switch',
					isParent: false,
					expanded: false,
					key: data.switchKey,
					unitId: data.unitId,
				};
			},
			// map Units collection (parent level) into a standard displayble data collection
			mapEndpointDefenders: (data) => {
				return data.map((u) => {
					return {
						name: u.name,
						model: u.modelName,
						serialNumber: u.serialNumber,
						location: u.location,
						status: u.status,
						cameraDefenseEnabled: data.cameraDefenseEnabled,
						licenseExpiration: !u.isLicensed
							? '0001-01-01T00:00:00Z'
							: !u.firmwareLicenseExpiration
							? '9999-12-31T23:59:59Z'
							: u.firmwareLicenseExpiration, // min = unlicensed, max = no exp
						type: 'EndpointDefender',
						isParent: false,
						expanded: false,
						key: u.endpointDefenderKey,
						unitId: u.unitId,
					};
				});
			},
			mapVmsServices: (data: VmsService[]) => {
				return data.map((v) => {
					return {
						name: v.name,
						model: v.productName,
						serialNumber: null,
						location: v.serverUri,
						status: v.status,
						type: 'VmsService',
						isParent: false,
						expanded: false,
						key: v.vmsServiceKey,
						unitId: v.unitId,
					};
				});
			},
			mapManagedDeviceInternalSwitch: (data: ManagedDevice[]) => {
				return data
					.filter((md) => md.type === ManagedDeviceType.InternalSwitch)
					?.map((md) => {
						const customData = JSON.parse(md.customData);
						return {
							name: md.name,
							model: md.modelName,
							serialNumber: md.serialNumber,
							location: md.location,
							status: md.status,
							cameraDefenseEnabled: customData?.cameraDefenseEnabled,
							licenseExpiration:
								customData == null
									? null
									: customData == !customData.isLicensed
									? '0001-01-01T00:00:00Z'
									: !customData.firmwareLicenseExpiration
									? '9999-12-31T23:59:59Z'
									: customData.firmwareLicenseExpiration, // min = unlicensed, max = no exp
							type: 'Switch',
							isParent: false,
							expanded: false,
							key: md.serialNumber, // customData.switchKey,
							unitId: md.unitId,
						};
					});
			},
            mapPowerSupplies: (data) => {
				return data
					.map(function (u) {
						return {
							name: u.name,
							model: u.modelName,
							serialNumber: u.serialNumber,
							status: u.status,
							bay: u.bay,
							type: 'PowerSupply',
							isParent: false,
							expanded: false,
							key: u.powerSupplyKey,
							unitId: u.unitId,
						};
					})
					?.sort((d1, d2) => (d1.bay > d2.bay ? 1 : -1));
			},
		};
	}
}
