<app-root-monitor-cloud-cell-account-status *ngIf="column?.cellType === 'account-status'" [status]="transformValue(value, column, tableItem)">
</app-root-monitor-cloud-cell-account-status>
<app-root-monitor-cloud-cell-account-subscription-status
	*ngIf="column?.cellType === 'account-subscription-status'"
	[subscription]="transformValue(value, column, tableItem)">
</app-root-monitor-cloud-cell-account-subscription-status>
<app-root-monitor-cloud-cell-alert-message *ngIf="column?.cellType === 'alert-message'" [alert]="tableItem"> </app-root-monitor-cloud-cell-alert-message>
<app-root-monitor-cloud-cell-alert-status *ngIf="column?.cellType === 'alert-status'" [status]="value"> </app-root-monitor-cloud-cell-alert-status>
<app-root-monitor-cloud-cell-network-link-status *ngIf="column?.cellType === 'network-link-status'" [data]="transformValue(value, column, tableItem)">
</app-root-monitor-cloud-cell-network-link-status>
<app-root-monitor-cloud-cell-user-roles *ngIf="column?.cellType === 'user-roles'" [user]="tableItem"> </app-root-monitor-cloud-cell-user-roles>
<app-root-monitor-cloud-cell-unit-title *ngIf="column?.cellType === 'unit-title'" [unit]="tableItem" (click)="handleCellClick(column, tableItem)">
</app-root-monitor-cloud-cell-unit-title>
<app-root-monitor-cloud-cell-unit-updates *ngIf="column?.cellType === 'unit-updates'" [updateTags]="tableItem[column.primaryKey]">
</app-root-monitor-cloud-cell-unit-updates>
<app-root-monitor-cloud-cell-manage-task-status *ngIf="column?.cellType === 'manage-task-status'" [status]="tableItem[column.primaryKey]">
</app-root-monitor-cloud-cell-manage-task-status>
<app-root-monitor-cloud-cell-manage-task-instance-summary
	*ngIf="column?.cellType === 'manage-task-instance-summary'"
	[instances]="tableItem[column.primaryKey]">
</app-root-monitor-cloud-cell-manage-task-instance-summary>
<!-- Common Cells -->
<app-shared-monitor-common-data-table-cell-types [value]="value" [column]="column" [tableItem]="tableItem"></app-shared-monitor-common-data-table-cell-types>
